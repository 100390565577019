<template>
	<div class="card">
		<Card>
			<template #title>
				<h4>{{$t('Póliza')}}</h4>
			</template>
			<template #content>
                <div class="p-grid">
                    <div class="p-col-4">
                        {{$t('Número')}}: <strong>{{inf.codigo2+inf.renovacion}}</strong>			
                    </div>
                    <div class="p-col-2">
                        {{$t('Cliente')}}: <strong>{{inf.cliente}}</strong>			
					</div>
                    <div class="p-col-3" >
                        <strong v-if="i18n.locale() == 'es'">{{detalle.poliza_nombre}}</strong> 
                        <strong v-else>{{detalle.poliza_name}}</strong> 			
					</div>
                    <div class="p-col-2">
						<Avatar icon="bi bi-shield-exclamation" class="p-mr-2" style="background-color:yellow; color: #ffffff" v-if="inf.estado==4"/>
						<Avatar icon="bi bi-shield-check" class="p-mr-2" style="background-color:green; color: #ffffff" v-if="inf.estado==5"/>
						<Avatar icon="bi bi-shield-slash" class="p-mr-2" style="background-color:red; color: #ffffff" v-if="inf.estado==6"/>
						<strong v-if="i18n.locale() == 'es'">{{inf.estado_nombre}}</strong> 
                        <strong v-else>{{inf.estado_name}}</strong>
                    </div>
                    <div class="p-col-1">
                        <i class="pi pi-bell p-mb-2" v-if="inf.migracion"/>
                    </div>
                </div>
			</template>
		</Card>
	</div>
</template>

<script>
	import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
        props: {
            detall: {
                type: Object,
                default: null,
            },
            datos: {
                type: Object,
                default: null,
            },
        },
        data() {
			return {
				i18n: null,
                inf: this.datos,
                detalle: this.detall,
			}
		},
		created() {
			this.i18n = useI18nPlugin();
		},
        methods: {

        }
	}
</script>
